@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .hover-enlarge {
    @apply transform transition duration-300 ease-in-out;
  }
  .hover-enlarge:hover {
    @apply scale-110;
  }
}
